import React from "react"

// const SnackAd = (props) => {
//
//   const ad_type = props.ad_type;
//   const ad_num = props.ad_num;
//   const ad_style = ad_type ==="leader" ? {minHeight: "250px", textAlign: "center"}
//     : {minHeight: "600px", textAlign:"center"};
//   const ad_id =
//     ad_type === "leader" &&  ad_num === 1 ? "snack_ldb"
//     : ad_type === "leader" &&  ad_num === 2 ? "snack_dex2"
//     : ad_type === "leader" &&  ad_num === 5 ? "snack_dex3"
//     : ad_type === "leader" &&  ad_num === 4 ? "snack_dex4"
//     : ad_type === "skyscraper" &&  ad_num === 1 ? "snack_dmpu"
//     : ad_type === "skyscraper" &&  ad_num === 2 ? "snack_dex5"
//     : "other";
//
//   const ad_container = ad_type ==="leader" ? {margin: "auto"}
//       : {margin: "auto", desktop: "position:-webkit-sticky", position: "sticky", top: "65px"};
//
//   return (
//     <div style = {ad_container}>
//       <div id={ad_id} style={ad_style}></div>
//     </div>
//   )
//
// }

class SnackAd extends React.Component {

  constructor(props) {
      super(props);
  }

  componentDidMount () {
    (function(){

    var site_id = 2511;
    var data_site_id = 0;

    var sn_cb = new Date().getMonth();
    var snack_hb = document.createElement('script');
    snack_hb.src = 'https://cdn-header-bidding.snack-media.com/assets/js/snack-loader/'+site_id+'?cb='+sn_cb;
    snack_hb.id = 'snack_ads';
    if(data_site_id){
      snack_hb.setAttribute('data-site-id',data_site_id);
      }

    document.body.appendChild(snack_hb);
    snack_hb.onerror = function(){
      document.body.removeChild(snack_hb);
      var snack_hb2 = document.createElement('script');
      snack_hb2.src = 'https://cdn2-header-bidding.snack-media.com/assets/js/snack-loader/'+site_id+'?cb='+sn_cb;
      snack_hb2.id = 'snack_ads';
      if(data_site_id){
          snack_hb2.setAttribute('data-site-id',data_site_id);
        }
        document.body.appendChild(snack_hb2);
      };
    })();
  }

render () {
  const ad_type = this.props.ad_type;
  const ad_num = this.props.ad_num;
  const ad_style = ad_type ==="leader" ? {minHeight: "250px", textAlign: "center"}
    : {minHeight: "600px", textAlign:"center"};
  const ad_id =
    ad_type === "leader" &&  ad_num === 1 ? "snack_ldb"
    : ad_type === "leader" &&  ad_num === 2 ? "snack_dex2"
    : ad_type === "leader" &&  ad_num === 5 ? "snack_dex3"
    : ad_type === "leader" &&  ad_num === 4 ? "snack_dex4"
    : ad_type === "skyscraper" &&  ad_num === 1 ? "snack_dmpu"
    : ad_type === "skyscraper" &&  ad_num === 2 ? "snack_dex5"
    : "other";

  const ad_container = ad_type ==="leader" ? {margin: "auto"}
      : {margin: "auto", desktop: "position:-webkit-sticky", position: "sticky", top: "65px"};

    return (
      <div style = {ad_container}>
        <div id={ad_id} style={ad_style}></div>
      </div>
    );
  }
}

export default SnackAd


// AD UNITS

// Leaderboard/Billboard #1 (D 970x250, 970x90, 728x90 / M 300x250, 320x50, 320x100)
//
// <div id="snack_ldb" style="min-height: 250px; text-align: center;"></div>
//
// Leaderboard/Billboard #2 (D 970x250, 970x90, 728x90 / M 300x250, 320x50, 320x100)
//
// <div id="snack_dex2" style="min-height: 250px; text-align: center;"></div>
//
// Leaderboard/Billboard #3 (D 970x250, 970x90, 728x90 / M 300x250, 320x50, 320x100)
//
// <div id="snack_dex3" style="min-height: 250px; text-align: center;"></div>
//
// Leaderboard/Billboard #4 (D 970x250, 970x90, 728x90 / M 300x250, 320x50, 320x100)
//
// <div id="snack_dex4" style="min-height: 250px; text-align: center;"></div>
//
// Sidebar DMPU/Skyscraper #1 (D 300x600, 300x250, 160x600, 120x600)
//
// <div id="snack_dmpu" style="min-height: 600px; text-align: center;"></div>
//
// Sidebar DMPU/Skyscraper #2 (D 300x600, 300x250, 160x600, 120x600)
//
// <div id="snack_dex5" style="min-height: 600px; text-align: center;"></div>
