import React from "react"
import PropTypes from 'prop-types';

class SnackAd extends React.Component {

  constructor(props) {
      super(props);
  }

  componentDidMount () {
      this.isMobile = window.innerWidth < 1200;
      // this.isMobile = window.innerWidth < 480;
  }

render () {

  const ad_type = this.props.ad_type;
  const ad_num = this.props.ad_num;

  // console.log("window:"+window.innerWidth);
  // console.log("windowisMobile:"+isMobile);

  const ad_style = ad_type ==="leader" ? {minHeight: "250px", textAlign: "center"}
    : this.isMobile === true ? {minHeight: "250px", textAlign: "center"}
    : {minHeight: "600px", textAlign:"center"};
  const ad_id =
    ad_type === "leader" &&  ad_num === 1 ? "snack_ldb"
    : ad_type === "leader" &&  ad_num === 2 ? "snack_dex2"
    : ad_type === "leader" &&  ad_num === 5 ? "snack_dex3"
    : ad_type === "leader" &&  ad_num === 4 ? "snack_dex4"
    : ad_type === "skyscraper" &&  ad_num === 1 ? "snack_dmpu"
    : ad_type === "skyscraper" &&  ad_num === 2 ? "snack_dex5"
    : "other";

    // : ad_type === "skyscraper" &&  ad_num === 1 && this.isMobile === true ? "snack_dex3"
    // : ad_type === "skyscraper" &&  ad_num === 2 && this.isMobile === true ? "snack_dex4"

    const ad_show =
      this.isMobile === true && ad_type === "leader" ? "show"
      : this.isMobile === true && ad_type === "skyscraper" ? "dontshow"
      : this.isMobile === false && ad_num === 2 && ad_type === "leader" ? "dontshow"
      : this.isMobile === false && ad_num === 5  ? "dontshow"
      : "show";

  const ad_container = ad_type ==="leader" ? {margin: "auto"}
      : this.isMobile === true ? {margin: "auto"}
      : {margin: "auto", desktop: "position:-webkit-sticky", position: "sticky", top: "65px"};

if(ad_show==="show"){
  return (
    <div style = {ad_container}>
      <div id={ad_id} style={ad_style}></div>
    </div>
  )
}
else {
  return null;
}

}
}

export default SnackAd

SnackAd.propTypes = {
  ad_type: PropTypes.string,
  ad_num:PropTypes.number
};


// AD UNITS

// Leaderboard/Billboard #1 (D 970x250, 970x90, 728x90 / M 300x250, 320x50, 320x100)
//
// <div id="snack_ldb" style="min-height: 250px; text-align: center;"></div>
//
// Leaderboard/Billboard #2 (D 970x250, 970x90, 728x90 / M 300x250, 320x50, 320x100)
//
// <div id="snack_dex2" style="min-height: 250px; text-align: center;"></div>
//
// Leaderboard/Billboard #3 (D 970x250, 970x90, 728x90 / M 300x250, 320x50, 320x100)
//
// <div id="snack_dex3" style="min-height: 250px; text-align: center;"></div>
//
// Leaderboard/Billboard #4 (D 970x250, 970x90, 728x90 / M 300x250, 320x50, 320x100)
//
// <div id="snack_dex4" style="min-height: 250px; text-align: center;"></div>
//
// Sidebar DMPU/Skyscraper #1 (D 300x600, 300x250, 160x600, 120x600)
//
// <div id="snack_dmpu" style="min-height: 600px; text-align: center;"></div>
//
// Sidebar DMPU/Skyscraper #2 (D 300x600, 300x250, 160x600, 120x600)
//
// <div id="snack_dex5" style="min-height: 600px; text-align: center;"></div>
