import React from "react"
import {Link} from "gatsby"
// import {Link, StaticQuery, graphql} from 'gatsby'
import styles from "./Header.module.scss"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import logo from "../images/fpl_green.png"
import twitter_logo from "../images/twitter-white3.png"
import fpl_logo from "../images/pl-logo-white.png"
import fpl_alerts_banner from "../images/ucl_banner.png"

  // <div className ={styles.container}>

const Header = () => (
<>
    <div>
      <img
        src={fpl_alerts_banner} alt="fpl_alerts_banner"
        width="100%"
      />
    </div>
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark"  sticky="top" style ={{margin:"0 0 20px 0"}}>
      <Navbar.Brand href="/">
        <img
          src={logo} alt="Logo"
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{' '}
        &nbsp; FPL Alerts
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/videprinter/">Videprinter</Nav.Link>
          <NavDropdown title="Leagues" id="collasible-nav-dropdown">
            <NavDropdown.Item href="/elite-tt/">Elite20 TT</NavDropdown.Item>
            <NavDropdown.Item href="/elite-tt-q/">Elite20 TT Qualifier</NavDropdown.Item>
            <NavDropdown.Item href="/ett-lms/">Elite20 TT LMS</NavDropdown.Item>
            <NavDropdown.Item href="/badger-baiters/">Badger Baiters XIV</NavDropdown.Item>
            <NavDropdown.Item href="/beer-vs-beer/">Beer vs Beer</NavDropdown.Item>
            <NavDropdown.Item href="/big-money-league/">Big Money League</NavDropdown.Item>
            <NavDropdown.Item href="/c-fpl/">Comedians FPL</NavDropdown.Item>
            <NavDropdown.Item href="/inner-circle/">The IC</NavDropdown.Item>
            <NavDropdown.Item href="/inner-circle-h2h/">The IC H2H</NavDropdown.Item>
            <NavDropdown.Item href="/leg-int/">Legitimately Interested</NavDropdown.Item>
            <NavDropdown.Item href="/nifty/">Nifty Classic</NavDropdown.Item>
            <NavDropdown.Item href="/mzansi-global/">mZAnsi GLOBAL</NavDropdown.Item>
            <NavDropdown.Item href="/fantasy-football-celeb/">Fantasy Football Celebs</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Stats" id="collasible-nav-dropdown">
            <NavDropdown.Item href="/stats/">GW Stats</NavDropdown.Item>
            <NavDropdown.Item href="/player-stats/">Player Stats</NavDropdown.Item>
            <NavDropdown.Item href="/team-stats/">Team Stats</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav>
          <Nav.Link href="/ucl/">UCL</Nav.Link>
          <Nav.Link href="/skyff/">Sky FF</Nav.Link>
          <Nav.Link href="http://dreamteamalerts.com/">Dream Team</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link href="https://twitter.com/fpl_alerts">
            <img
              src={twitter_logo} alt="twitter_logo"
              width="30"
              height="30"
            />{' '}
          </Nav.Link>
          <Nav.Link href="https://fantasy.premierleague.com/">
            <img
              src={fpl_logo} alt="fpl_logo"
              width="24"
              height="30"
            />{' '}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>

</>
)

export default Header
